import React from "react";
// import titlesponsor from "../../assets/images/about/title-spansor/title-sponsor.png";
  import grunells from "../../assets/images/about/title-spansor/grunells.png";
import vertically from "../../assets/images/about/title-spansor/vertically.png";
import pic1 from "../../assets/images/about/title-spansor/pic1.png";
import pic2 from "../../assets/images/about/title-spansor/pic2.png";
import pic3 from "../../assets/images/about/title-spansor/pic3.png";
import pic4 from "../../assets/images/about/title-spansor/pic4.png";
import titlebg from "../../assets/images/about/title-spansor/title-spancor-bg.png";

const Titlesponsorspage = () => {
  return (
    <div className="title-sporsor-page-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img
              className="img-fluid"
              src={titlebg}
              width="100%"
              alt="Before"
            />
          </div>
          <div className="col-md-8">
            <h3 className="title-heading"> TITLE SPONSOR - GRANULES </h3>
            <div className="grunells-wrapper">
              <img
                className="img-fluid"
                src={grunells}
                width="100%"
                alt="Before"
              />
              <p>
                {" "}
                Translating Science & Technology into High-Quality
                Pharmaceutical Products over 3 decades.
              </p>
            </div>
            <p>
              {" "}
              Granules India is a global pharmaceutical leader, renowned for vertical integration and operational excellence. With eight manufacturing facilities worldwide, they serve 300+ customers in 80+ countries. They have a broad product range including Active Pharmaceutical Ingredients (API) to Finished Doses (FD), and boast the world's largest Paracetamol API facility. Notably, Granules is committed to sustainability, pioneering a 100-acre Green Pharmaceutical Zone powered by 24x7 carbon-free green energy.
            </p>
          
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-md-12">
          <h4> Uma Chigurupati</h4>
            <p> A force to be reckoned with in the realm of marathon running, Mrs. Uma Chigurupati has rewritten the record books with her trailblazing spirit and relentless determination. Alongside her partner in life and in sport, Dr. Krishna Prasad Chigurupati, she claimed a unique spot in the Guinness World Records as the sole married couple to have triumphed over marathons in the challenging terrains of Antarctica and the North Pole.
</p>
            <p> Their thirst for pushing boundaries didn't end there. The couple further undertook the grueling “World Marathon Challenge”, showcasing their stamina by running seven half marathons on seven continents in a record-breaking span of six days, 18 hours, and 30 minutes. This feat was made all the more extraordinary as Mrs. Chigurupati became the first woman in the world to conquer this arduous challenge.</p>
            <p> Outside of her sporting exploits, Mrs. Chigurupati is also a skilled leader, having served as the President of Ikebana International, Hyderabad Chapte</p>
            <h4> Highlighted achievements:</h4>
            <p> - Guinness World Records holder with her husband for completing marathons in both Antarctica and the North Pole.</p>
         <p>  - Competed in the "World Marathon Challenge", running seven half marathons across seven continents in under seven days. </p>  
         <p> - Became the first woman globally to achieve the aforementioned feat.</p>
         <p> - Demonstrated leadership skills as President of Ikebana International, Hyderabad Chapter.</p>

          </div>

        </div>

        {/* <div className="row mt-4 mb-4">
          <div className="col-md-3 product-list-wrapper">
          <img
                className="img-fluid"
                src={vertically}
                alt="vertically"
              />
            <h3> Vertically-Integrated Model</h3>
            <p>
              {" "}
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, 
            </p>
          </div>
          <div className="col-md-3 product-list-wrapper">
          <img
                className="img-fluid"
                src={vertically}
                alt="vertically"
              />
            <h3> Economies of Scale</h3>
            <p>
              {" "}
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, 
            </p>
          </div>
          <div className="col-md-3 product-list-wrapper">
          <img
                className="img-fluid"
                src={vertically}
                alt="vertically"
              />
            <h3> Strong R&D Facilities</h3>
            <p>
              {" "}
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, 
            </p>
          </div>
          <div className="col-md-3 product-list-wrapper">
          <img
                className="img-fluid"
                src={vertically}
                alt="vertically"
              />
            <h3> Product Portfolio</h3>
            <p>
              {" "}
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, 
            </p>
          </div>
        </div> */}

        <div className="row mt-4 mb-4 picture-block">

            <h3 className="title-heading"> PICTURES </h3>

            <div className="col-md-3">
            <img
                className="img-fluid"
                src={pic1}
                alt="pic1"
              />
            </div>
            <div className="col-md-3">
            <img
                className="img-fluid"
                src={pic2}
                alt="pic1"
              />
            </div>
            <div className="col-md-3">
            <img
                className="img-fluid"
                src={pic3}
                alt="pic1"
              />
            </div>
            <div className="col-md-3">
            <img
                className="img-fluid"
                src={pic4}
                alt="pic1"
              />
            </div>
        </div>

      </div>
    </div>
  );
};
export default Titlesponsorspage;
