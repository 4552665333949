import React from "react";

import Layout from "../layout/index";

import Seo from "../components/seo/index";

import Titlesponsorbreadcrumbpage from "../components/about/title-sponsor-breadcrumb";

import Titlesponsorspage  from "../components/about/titlesponsorpage";

import Socialiconright from "../components/home/social-icon-right";

const Titlesponsor = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Title Sponsor" />
      <Layout isSticky>
        <Titlesponsorbreadcrumbpage />
        <Socialiconright />
        <Titlesponsorspage  />
      </Layout>
    </div>
  );
};

export default Titlesponsor;
